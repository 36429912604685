import { useQuery } from 'react-query';
import { API_KEY, API_ORDER_LIST } from '../../settings';
import { useUser } from '../../contexts/UserContext';
import IOrder from '../../types/Order';
import OrderStateEnum from '../../types/OrderState';
import { INVOICESTATE } from '../../components/PaymentDetails/utils/const';
import { isOrderShippedAndOverdue } from '../../utils';

export function useOrderInfo(orderId: string) {
  const { authToken, lang } = useUser();

  const { data, isSuccess, isLoading, isError, refetch } = useQuery(
    `orderInfo_${orderId}`,
    async (): Promise<IOrder> => {
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Accept-Language': lang,
          Authorization: `Bearer ${authToken}`,
          'x-api-key': `${API_KEY}`,
        },
      };
      const response = await fetch(`${API_ORDER_LIST}/${orderId}?includeDeliveryTimes=true`, { ...options });
      return response.json();
    },
  );

  const showReceiptButton =
    data?.invoiceState === INVOICESTATE.REQUESTABLE || data?.invoiceState === INVOICESTATE.NOT_REQUESTABLE_FR_AVAILABLE;
  const isInvoiceInProgress =
    data?.invoiceState === INVOICESTATE.IN_PROGRESS || data?.invoiceState === INVOICESTATE.WAITING_FOR_SHOPCODE;
  const isInvoiceRequestable = data?.invoiceState === INVOICESTATE.REQUESTABLE;
  const isInvoiceAvailable = data?.invoiceState === INVOICESTATE.AVAILABLE;
  const overdueData = {
    orderState: data?.orderState,
    shippingType: data?.shippingType,
    isDeliveryDelayed: data?.isDeliveryDelayed,
  };

  const checkIsOrderShippedAndOverdue = isOrderShippedAndOverdue(overdueData);

  return {
    data,
    isOrderCanceled: data?.orderState === OrderStateEnum.Cancelled,
    isSuccess,
    isLoading,
    isError,
    refetch,
    showReceiptButton,
    isInvoiceInProgress,
    isInvoiceRequestable,
    isInvoiceAvailable,
    checkIsOrderShippedAndOverdue,
  };
}
